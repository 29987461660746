import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  /* overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
  li {
    line-height: 3rem;
    height: 3rem;
  }
  a {
    line-height: 3.3;
    line-height: 3rem;
    height: 3rem;
    display: inline-block;
    overflow: hidden;
  } */
`

const SocialWidget = () => {
  return (
    <Container className="social rounded-md bg-gray-shaded">
      <ul className="flex gap-1 ">
        <li className="shrink-0 ">
          <a
            href="https://www.instagram.com/brechtfestival"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-instagram"></span>
          </a>
        </li>
        <li className="shrink-0">
          <a
            href="https://www.facebook.com/brechtfestival"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-facebook"></span>
          </a>
        </li>

        {/* <li className="shrink-0 ">
          <a
            href="https://twitter.com/BrechtfestivalA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-twitter"></span>
          </a>
        </li> */}
        <li className="shrink-0 -">
          <a
            href="https://www.youtube.com/channel/UCIwgbBp2sBml9Olf8D8Dx6A"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-youtube"></span>
          </a>
        </li>
      </ul>
    </Container>
  )
}

export default SocialWidget
