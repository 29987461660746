import React from 'react'
import styled from 'styled-components'
import { _localizeText } from '../../core/utils'

const Container = styled.div`
  /* background-color: var(--color-white);
  overflow: hidden;
  border-radius: 0.6rem;
  padding-left: 1.5em;
  padding-right: 1.5em; */

  a.btn {
    /* line-height: 3.3; */
    /* max-height: 3em; */
    display: inline-block;
    /* padding: 0 0.2em; */
    /* padding: 1em; */
    padding: 0em 0.625em 0;
    text-align: center;
    line-height: 4.7rem;
    span {
      transform: translateY(0.1em);
    }
  }
  /* li:first-child a {
    padding-left: 0;
  }
  li:last-child a {
    padding-right: 0;
  } */
  /* &::before {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    background-color: var(--color-black-5);
  } */
`

const Social = () => {
  return (
    <div className="social">
      <div className="label mb-1 ">{_localizeText('social')}</div>
      <Container className="rounded-md md:inline-block text-lg list-">
        <ul className="flex flex-col- md:flex-row- justify-center- md:justify-start- gap-05">
          <li>
            <a
              href="https://www.instagram.com/brechtfestival/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--big"
            >
              <span className="icon-instagram"></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/brechtfestival/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--big"
            >
              <span className="icon-facebook"></span>
            </a>
          </li>

          {/* <li>
            <a
              href="https://twitter.com/BrechtfestivalA"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--big"
            >
              <span className="icon-twitter"></span>
            </a>
          </li> */}
          <li>
            <a
              href="https://www.youtube.com/channel/UCIwgbBp2sBml9Olf8D8Dx6A"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--big"
            >
              <span className="icon-youtube"></span>
            </a>
          </li>
        </ul>
      </Container>
    </div>
  )
}

export default Social
